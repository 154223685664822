
import LayeredParallax from "@/components/base/LayeredParallax"
import Button from "@/components/base/Button"
import LoginProfileButtons from "@/components/auth/LoginProfileButtons"

export default {
    name: "LandshutRegionHeader",
    components: { LayeredParallax, Button, LoginProfileButtons },
    data: () => ({
        parallaxLayers: [
            require("@/assets/images/region/landshut/parallax/layer1.png"),
            require("@/assets/images/region/landshut/parallax/layer2.png"),
            require("@/assets/images/region/landshut/parallax/layer3.png"),
            require("@/assets/images/region/landshut/parallax/layer4.png"),
            require("@/assets/images/region/landshut/parallax/layer5.png"),
        ],
        parallaxLayerTransforms: [1.6, 1.5, 1.7, 2, 2.2],
    }),
}
